export default [
  {
    title: 'Retour à la liste',
    route: 'apps-tickets',
    icon: 'ArrowLeftIcon',
  },
  {
    header: 'Administration',
  },
  {
    title: 'Tableau de bord',
    route: 'apps-administration',
    icon: 'HomeIcon',
  },
  {
    title: 'Utilisateurs',
    route: 'apps-users-list',
    icon: 'UsersIcon',
  },
  {
    title: 'Paramètres',
    route: 'apps-administration-parametres',
    icon: 'SettingsIcon',
  },
  {
    title: 'Notes de MAJ',
    route: 'apps-administration-maj',
    icon: 'FileTextIcon',
  },
]
