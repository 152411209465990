<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
<!--    <div class="header-navbar-shadow" />-->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        class="content-area-wrapper"
        :class="contentWidth === 'boxed' ? 'container p-0' : null"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <portal-target
          name="content-renderer-sidebar-left"
          slim
        />
        <div class="content-right bg-dark">
          <div class="content-wrapper">
            <div class="content-body">
              <b-alert
                  v-if="messageGlobal && messageGlobal !== ''"
                  variant=" alert-liste bg-info bg-darken-2 text-white no-radius"
                  class="m-1"
                  show
                  dismissible
              >
                <div class="alert-body ml-2">
                  <p><feather-icon
                      icon="InfoIcon"
                      class="icon-filter-calendar text-white mr-1 d-md-inline d-none"
                  />
                    <span class="text-white">{{ messageGlobal }} </span>
                  </p>
                </div>
              </b-alert>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BAlert,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    AppBreadcrumb,
    BAlert,
  },
  setup(props, context) {
    const { routerTransition, contentWidth } = useAppConfig()

    const messageGlobal = ref(null)

    messageGlobal.value = JSON.parse(localStorage.getItem('parameters')).message_global || null

    const fetchParameters = () => {
      context.root.$http.get('/api/parametres/get').then(res => {
        localStorage.setItem('parameters', JSON.stringify(res.data.parametres))
        messageGlobal.value = res.data.parametres.message_global || null
      })
    }
    fetchParameters()
    setInterval(() => { fetchParameters() }, 60000)

    return {
      routerTransition, contentWidth, messageGlobal, fetchParameters,
    }
  },
}
</script>

<style>

</style>
